export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)": [~4,[2]],
		"/(app)/cctv": [~5,[2]],
		"/(app)/docs": [6,[2]],
		"/(app)/forgot-password": [~7,[2]],
		"/(app)/login": [~8,[2]],
		"/(app)/misc": [9,[3]],
		"/(app)/misc/barcode": [10,[3]],
		"/(app)/misc/client": [~11,[3]],
		"/(app)/misc/code": [~12,[3]],
		"/(app)/misc/components": [13,[3]],
		"/(app)/misc/default-data": [~14,[3]],
		"/(app)/misc/dialog": [15,[3]],
		"/(app)/misc/email": [~16,[3]],
		"/(app)/misc/fire-and-forget": [17,[3]],
		"/(app)/misc/offline-form": [~18,[3]],
		"/(app)/misc/print": [19,[3]],
		"/(app)/registrate": [~20,[2]],
		"/(app)/registrate/client": [~21,[2]],
		"/(app)/registrate/user": [~22,[2]],
		"/(app)/schedule": [~23,[2]],
		"/(app)/schedule/summary": [~25,[2]],
		"/(app)/schedule/[state]": [~24,[2]],
		"/(app)/sessionerror": [~26,[2]],
		"/(app)/subroot": [27,[2]],
		"/(app)/user": [~28,[2]],
		"/(app)/user/accept": [~29,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';